import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Login, CheckExpiration } from './components/Login';
import { useNavigate } from 'react-router-dom';

function App() {
  const navigate = useNavigate();

  useEffect(() => {
    //validate token on load
    validateToken()
  }, [])

  const validateToken = () => {
    if (!CheckExpiration())
    {
      //Redirect to login if token is expired or non existent
      navigate('/login')
    }
  }

  return (
      <Routes>
        <Route path="/" element={
          <div className="App">
            <h1>Logged in, show app!</h1>
          </div> } />
        <Route path="/login" element={ <Login /> } />
      </Routes>
  );
}

export default App;
