import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Login() {

    //User must login after this number of days
    const EXPIRE_IN_DAYS = 30
    const navigate = useNavigate();
    
    useEffect(() => {
        if(CheckExpiration()) {
            //Redirect to home if token is valid
            navigate('/')
        }
        //generate new token if page is accessed
        const token = localStorage.setItem('token', generateToken());
    }, []);

    const generateToken = () => {
        const tokenObject = { expire: getExpirationDate()};
        //base64 encode tokenObject
        const token = btoa(JSON.stringify(tokenObject));
        return token;
    }
    
    //get timestamp EXPIRE_IN_DAYS days from now
    const getExpirationDate = () => {
        let date = new Date();
        date.setDate(date.getDate() + EXPIRE_IN_DAYS);
        return date;
    }

    return (
        <>
        <h1>Logging in!</h1>
        </>
    );
}

/**
 * Checks the local storage for token and validate if it has expired, if expired, remove token from local storage
 * @returns {boolean}
 */
function CheckExpiration() {
    const token = localStorage.getItem('token');    
    //console.log("check expire")
    //console.log(token)
    try {
        if (!token) {
            return false;
        }
        else {
            const expire = JSON.parse(atob(token)).expire
            //console.log("expire " + new Date(expire).getTime())
            //console.log("now " + Date.now())
            //console.log(new Date(expire).getTime() > Date.now())
            if(new Date(expire) > Date.now()) {
                return true;
            }
            else {
                localStorage.removeItem('token');
                return false;
            }
        }
    }
    catch(e) {
        //console.log(e)
        return false;
    }
}

export { Login, CheckExpiration };